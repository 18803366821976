import logo from './logo-respio.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <section>

      </section>
      <footer className="App-footer">
        <p>respio GmbH<br/>Konrad-Zuse-Platz 11, 81829 München</p>
        <p><a href={'mailto:info@respio.de'}>info@respio.de</a> &middot; 089 2000 8313-0</p>
        <p>Geschäftsführer: <a href={'https://www.linkedin.com/in/sebastian-michael-blum/'}>Sebastian Blum</a><br/>Amtsgericht München, HRB 294408</p>
      </footer>
    </div>
  );
}

export default App;
